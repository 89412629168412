// import user from 'types/user';

export default async function() {
    const config = useRuntimeConfig();
    const { data: userData } = await useFetch(config.public.apiBase + '/users');
    const usersFetched = [];
    const assigneeList = userData?._rawValue;
    for (const user of assigneeList)
        usersFetched.push({
            name: user.name,
            email: user.email,
            avatar: ''
        });
    return usersFetched;
}
